<template>
  <div
    class="user-profile-sidebar d-none"
    :class="{'show': shallShowActiveChatContactSidebar}"
  >
    <!-- Header -->
    <header
      v-if="contact"
      class="user-profile-header border-bottom"
    >
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            size="120"
            :src="contact.avatar"
            class="border-0"
          />
          <!-- <span
            class="avatar-status-xl"
            :class="`avatar-status-${contact.status}`"
          /> -->
        </div>
        <h4 class="chat-user-name">
          {{ contact.name }}
        </h4>
        <span class="user-post text-capitalize">{{ contact.role }}</span>
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-profile-sidebar-area p-0"
      backdrop-variant="primary"
    >

      <!-- About -->
      <!-- <h6 class="section-label mb-1">
        About
      </h6> -->
      <!-- <p>{{ contact.about }}</p> -->

      <!-- Personal Info -->
      <!-- <div class="personal-info">
        <h6 class="section-label mb-1 mt-3">
          Personal Information
        </h6>
        <ul class="list-unstyled">
          <li class="mb-1">
            <feather-icon
              icon="MailIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">lucifer@email.com</span>
          </li>
          <li class="mb-1">
            <feather-icon
              icon="PhoneCallIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">+1(123) 456 - 7890</span>
          </li>
          <li>
            <feather-icon
              icon="ClockIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">Mon - Fri 10AM - 8PM</span>
          </li>
        </ul>
      </div> -->

      <!-- Media Files -->
      <div class="media-history">
        <div class="common-content">
          <div class="d-flex align-items-center px-1 mt-75">
            <b-img :src="require('@/assets/images/icons/media-icon.svg')" width="20" class="mr-25"></b-img>
            <h5 class="align-middle mb-0">Media</h5>
          </div>
          <b-tabs content-class="mt-1">
            <b-tab title="Images" active>
              <ul class="media-info image-view">
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-11.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-04.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-05.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-03.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-07.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-13.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-04.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-05.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-03.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-07.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-13.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-05.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-03.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-07.jpg')" rounded></b-img>
                  </div>
                </li>
                <li>
                  <div class="media-upload">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-13.jpg')" rounded></b-img>
                  </div>
                </li>
              </ul>
            </b-tab>

            <!-- <b-tab title="Videos">
              <ul class="media-info video-view">
                <li>
                  <div class="media-upload">
                    <b-embed type="video" aspect="4by3" controls poster="poster.png">
                      <source src="dev-stories.webm" type="video/webm">
                      <source src="dev-stories.mp4" type="video/mp4">
                    </b-embed>
                  </div>
                </li>
              </ul>
            </b-tab> -->

            <b-tab title="Links">
              <b-list-group class="media-info chat-view p-0 m-0">
                <b-list-group-item class="flex-column align-items-start bg-default border-0">
                  <div class="d-flex">
                    <b-avatar
                      size="24"
                      class="badge-minimal mr-75 mt-25 text-default"
                      variant="secondary"
                    />
                    <div class="chat-info flex-grow-1">
                      <h5 class="mb-0">
                        {{ contact.name }}
                        <small class="chat-time ml-25">23/01/2023</small>
                      </h5>
                      <p class="mb-0">Please check this linkedin Post <b-link>https://www.linkedin.com/help/linkedin/answer/a518996/post-and-share-content-on-linkedin?lang=en</b-link>
                      </p>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item class="flex-column align-items-start bg-default border-0">
                  <div class="d-flex">
                    <b-avatar
                      size="24"
                      class="badge-minimal mr-75 mt-25 text-default"
                      variant="secondary"
                    />
                    <div class="chat-info flex-grow-1">
                      <h5 class="mb-0">
                        {{ contact.name }}
                        <small class="chat-time ml-25">23/01/2023</small>
                      </h5>
                      <p class="mb-0">I recently cross this medium blog and u should definitely readout this <b-link>https://medium.com/@uxsurvivalguide/ux-portfolios-you-suck-at-talking-about-yourself-let-chatgpt-do-it-for-you-b9708dfe4175 </b-link>
                      </p>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item class="flex-column align-items-start bg-default border-0">
                  <div class="d-flex">
                    <b-avatar
                      size="24"
                      class="badge-minimal mr-75 mt-25 text-default"
                      variant="secondary"
                    />
                    <div class="chat-info flex-grow-1">
                      <h5 class="mb-0">
                        {{ contact.name }}
                        <small class="chat-time ml-25">23/01/2023</small>
                      </h5>
                      <p class="mb-0">Yeah, it's awesome! You should deliver totally check it out <b-link>www.zircly.com</b-link>
                      </p>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item class="flex-column align-items-start bg-default border-0">
                  <div class="d-flex">
                    <b-avatar
                      size="24"
                      class="badge-minimal mr-75 mt-25 text-default"
                      variant="secondary"
                    />
                    <div class="chat-info flex-grow-1">
                      <h5 class="mb-0">
                        {{ contact.name }}
                        <small class="chat-time ml-25">23/01/2023</small>
                      </h5>
                      <p class="mb-0">I recently cross this medium blog and u should definitely readout this <b-link>https://medium.com/@uxsurvivalguide/ux-portfolios-you-suck-at-talking-about-yourself-let-chatgpt-do-it-for-you-b9708dfe4175 </b-link>
                      </p>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-tab>
          </b-tabs>
        </div>
        </div>
      <!-- End Media Files -->

      <!-- More Options -->
      <div class="more-options my-1 border-top pt-75">
        <!-- <h6 class="section-label mb-1 mt-3">
          Options
        </h6> -->
        <ul class="list-unstyled">
          <li class="cursor-pointer">
            <feather-icon icon="BellOffIcon" size="18" class="mr-50 text-default" />
            <span class="align-middle">Mute Notifications</span>
          </li>
          <li class="cursor-pointer">
            <feather-icon icon="Trash2Icon" size="18" class="mr-50 text-default" />
            <span class="align-middle">Clear Messages</span>
          </li>
          <li class="cursor-pointer" @click="confirmBlockUser(data)">
            <feather-icon icon="SlashIcon" size="18" class="mr-50 text-default" />
            <span class="align-middle">Block <span class="username">Carrie Hawkins</span></span>
          </li>
          <li class="cursor-pointer">
            <feather-icon icon="InfoIcon" size="18" class="mr-50 text-default" />
            <span class="align-middle">Report Carrie Hawkins</span>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BEmbed, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,
    BEmbed,
  },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
