import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/chats/chats-and-contacts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/chats/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat(ctx, {id,type}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/chats/getChat`,{id,type})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/chats/sendMessage`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeMessage(ctx, Id) {
      return new Promise((resolve, reject) => {
          axios
              .delete(`/chats/removeMessage/${Id}`)
              .then(response => {
                  resolve(response);
              })
              .catch(error => {
                  reject(error);
              });
      });
  },
  },
}
