var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-profile-sidebar",class:{'show': _vm.shallShowUserProfileSidebar}},[_c('header',{staticClass:"chat-profile-header"},[_c('span',{staticClass:"close-icon"},[_c('feather-icon',{attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.$emit('close-sidebar')}}})],1),_c('div',{staticClass:"header-profile-sidebar"},[_c('div',{staticClass:"avatar box-shadow-1 avatar-xl avatar-border"},[_c('b-avatar',{attrs:{"size":"70","src":_vm.profileUserData.avatar,"to":{
      name: 'employee-profile',
      params: { id: _vm.profileUserData.hashid },
    },"target":"_blank"}})],1),_c('h4',{staticClass:"chat-user-name"},[_vm._v(" "+_vm._s(_vm.profileUserData.name)+" ")]),_c('span',{staticClass:"user-post text-capitalize"},[_vm._v(_vm._s(_vm.profileUserData.role))])])]),_c('vue-perfect-scrollbar',{staticClass:"profile-sidebar-area scroll-area p-0",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('ul',{staticClass:"list-unstyled border-top pt-1 profile-status py-1"},[_c('li',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-left",modifiers:{"sidebar-left":true}}],staticClass:"mb-1 d-flex align-items-center cursor-pointer px-1"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"BookmarkIcon","size":"18","stroke":"#6E6B7B"}}),_c('span',{staticClass:"align-middle",on:{"click":function($event){return _vm.getSavedMessages()}}},[_vm._v("Saved Messages")])],1),_c('li',{staticClass:"mb-1 d-flex align-items-center cursor-pointer px-1"},[_c('b-link',{attrs:{"to":{
      name: 'employee-profile',
      params: { id: _vm.userData.hashid },
    },"target":"_blank"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"UserIcon","size":"18","stroke":"#6E6B7B"}}),_c('span',{staticClass:"align-middle"},[_vm._v("View Full Profile")])],1)],1)])]),_c('b-sidebar',{staticClass:"saved-message",attrs:{"id":"sidebar-left","left":"","shadow":"","backdrop":"","no-header":""}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between p-1 message-header"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{attrs:{"icon":"BookmarkIcon","stroke":"#6E6B7B","size":"20"}}),_c('h6',{staticClass:"mb-0 ml-25"},[_vm._v("Saved Messages")])],1),_c('feather-icon',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-left",modifiers:{"sidebar-left":true}}],attrs:{"icon":"ArrowRightIcon","stroke":"#6E6B7B","size":"20"}})],1),_c('b-list-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.Messages.length),expression:"Messages.length"}],staticClass:"search-message p-0 m-0"},_vm._l((_vm.Messages),function(message){return _c('b-list-group-item',{staticClass:"flex-column align-items-start bg-default"},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{staticClass:"badge-minimal mr-75 mt-25 text-default",attrs:{"src":message.senderAvatar,"size":"24","variant":"secondary","to":{
      name: 'employee-profile',
      params: { id: message.senderId },
    },"target":"_blank"}}),_c('div',{staticClass:"chat-info flex-grow-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(message.senderName)+" "),_c('small',{staticClass:"chat-time ml-25"},[_vm._v(_vm._s(_vm.formatDateToMonthShort(message.created_at, { hour: 'numeric', minute: 'numeric' })))])]),_c('div',{staticClass:"message-actions-wrapper"},[_c('div',{staticClass:"options-container"},[_c('feather-icon',{staticClass:"saved-post",attrs:{"icon":"BookmarkIcon","stroke":"#6E6B7B","size":"24"},on:{"click":function($event){return _vm.unsaveMessage(message)}}})],1)]),_c('p',{staticClass:"mb-0",staticStyle:{"letter-spacing":"1px","font-size":"14px"},domProps:{"innerHTML":_vm._s(message.message)}})])],1)])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }