<template>
  <div
    class="chat-profile-sidebar"
    :class="{'show': shallShowUserProfileSidebar}"
  >
    <!-- Header -->
    <header class="chat-profile-header">
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('close-sidebar')"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            size="70"
            :src="profileUserData.avatar"
            :to="{
        name: 'employee-profile',
        params: { id: profileUserData.hashid },
      }" target='_blank'
          />
          <!-- <span
            class="avatar-status-xl"
            :class="`avatar-status-${profileUserData.status}`"
          /> -->
        </div>
        <h4 class="chat-user-name">
          {{ profileUserData.name }}
        </h4>
        <span class="user-post text-capitalize">{{ profileUserData.role }}</span>
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="profile-sidebar-area scroll-area p-0"
    >

      <!-- About -->
      <!-- <h6 class="section-label mb-1">
        About
      </h6>
      <div class="about-user">
        <b-form-textarea
          v-model="profileUserData.about"
          placeholder="Something about yourself..."
          rows="5"
        />
      </div> -->

      <!-- Status -->
      <!-- <h6 class="section-label mb-1 mt-1">
        Status
      </h6>
      <b-form-radio-group
        id="user-status-options"
        v-model="profileUserData.status"
        stacked
      >
        <b-form-radio
          v-for="option in userStatusOptions"
          :key="option.value"
          :value="option.value"
          :class="`custom-control-${option.radioVariant}`"
        >
          {{ option.text }}
        </b-form-radio>
      </b-form-radio-group> -->

      <!-- Settings -->
      <!-- <h6 class="section-label mb-1 mt-2">
        Settings
      </h6>
      <ul
        v-if="profileUserData.settings"
        class="list-unstyled"
      > -->

        <!-- Two Step Auth -->
        <!-- <li class="d-flex justify-content-between align-items-center mb-1">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CheckSquareIcon"
              size="18"
              class="mr-75"
            />
            <span class="align-middle">Two-step Verification</span>
          </div>
          <b-form-checkbox
            v-model="profileUserData.settings.isTwoStepAuthVerificationEnabled"
            switch
          />
        </li> -->

        <!-- Notifications -->
        <!-- <li class="d-flex justify-content-between align-items-center mb-1">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="BellIcon"
              size="18"
              class="mr-75"
            />
            <span class="align-middle">Notification</span>
          </div>
          <b-form-checkbox
            v-model="profileUserData.settings.isNotificationsOn"
            switch
          />
        </li> -->

        <!-- Invite Friends -->
        <!-- <li class="mb-1 d-flex align-items-center cursor-pointer">
          <feather-icon
            icon="UserIcon"
            class="mr-75"
            size="18"
          />
          <span class="align-middle">Invite Friends</span>
        </li> -->

        <!-- Delete Account -->
        <!-- <li class="d-flex align-items-center cursor-pointer">
          <feather-icon
            icon="TrashIcon"
            class="mr-75"
            size="18"
          />
          <span class="align-middle">Delete Account</span>
        </li>
      </ul> -->

      <!-- <div class="mt-3">
        <b-button variant="primary">
          Logout
        </b-button>
      </div> -->
      <!-- Saved Message & Profile View -->
      <ul class="list-unstyled border-top pt-1 profile-status py-1">
        <li class="mb-1 d-flex align-items-center cursor-pointer px-1" v-b-toggle.sidebar-left>
          <feather-icon
            icon="BookmarkIcon"
            class="mr-75"
            size="18"
            stroke="#6E6B7B"
          />
          <span class="align-middle" @click="getSavedMessages()">Saved Messages</span>
        </li>
        <li class="mb-1 d-flex align-items-center cursor-pointer px-1">
          <b-link :to="{
        name: 'employee-profile',
        params: { id: userData.hashid },
      }" target='_blank' >
          <feather-icon
            icon="UserIcon"
            class="mr-75"
            size="18"
            stroke="#6E6B7B"
          />
          <span class="align-middle">View Full Profile</span>
          </b-link>
        </li>
      </ul>
    </vue-perfect-scrollbar>
    

    <b-sidebar id="sidebar-left" left shadow class="saved-message" backdrop no-header>
      <!-- Search Result View -->
      <!-- Top Fixed Search -->
      <div class="d-flex align-items-center justify-content-between p-1 message-header">
        <div class="d-flex align-items-center">
          <feather-icon icon="BookmarkIcon" stroke="#6E6B7B" size="20" />
          <h6 class="mb-0 ml-25">Saved Messages</h6>
        </div>
        <feather-icon icon="ArrowRightIcon" stroke="#6E6B7B" size="20" v-b-toggle.sidebar-left />
      </div>
      <b-list-group class="search-message p-0 m-0" v-show="Messages.length">
        <b-list-group-item class="flex-column align-items-start bg-default" v-for="message in Messages">
          <div class="d-flex">
            <b-avatar :src="message.senderAvatar" size="24" class="badge-minimal mr-75 mt-25 text-default" variant="secondary" :to="{
        name: 'employee-profile',
        params: { id: message.senderId },
      }" target='_blank' />
            <div class="chat-info flex-grow-1">
              <h5 class="mb-0">
                {{message.senderName}} <small class="chat-time ml-25">{{ formatDateToMonthShort(message.created_at, { hour: 'numeric', minute: 'numeric' }) }}</small>
              </h5>
               <!-- Hover Action -->
               <div class="message-actions-wrapper">
                <div class="options-container">
                  <!-- <b-img :src="require('@/assets/images/icons/reply-icon.svg')" width="24" class="reply-chaticon" v-b-tooltip.hover.v-default
                    title="Move to this Message"></b-img> -->
                  <feather-icon icon="BookmarkIcon" class="saved-post" stroke="#6E6B7B" size="24" @click="unsaveMessage(message)" />
                </div>
              </div>
              <p v-html="message.message" class="mb-0" style="letter-spacing: 1px; font-size: 14px"></p>
              <!-- <div class="media-upload">
                <ul class="p-0 mx-0 my-25">
                  <li class="mr-25 mb-25">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-04.jpg')" width="40px" hight="40px"
                      rounded></b-img>
                  </li>
                  <li class="mr-25 mb-25">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-04.jpg')" width="40px" hight="40px"
                      rounded></b-img>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar, BFormTextarea, BFormRadioGroup, BFormRadio, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { formatDateToMonthShort } from '@core/utils/filter'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BAvatar,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BButton,
    VuePerfectScrollbar,
    ToastificationContent
  },
  props: {
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },

  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const userStatusOptions = [
      { text: 'Online', value: 'online', radioVariant: 'success' },
      { text: 'Offline', value: 'offline', radioVariant: 'secondary' },
      { text: 'Busy', value: 'busy', radioVariant: 'danger' },
      // { text: 'Away', value: 'away', radioVariant: 'warning' },
    ]

    return {
      perfectScrollbarSettings,
      userStatusOptions,
      formatDateToMonthShort
    }
  },

  data() {
    return {
      userData: this.$cookies.get("userData"),
      Messages:[],
      gettingSavedMessage:false
    }
  },
  methods:{
    getSavedMessages(){
      this.gettingSavedMessage = true;
      this.$http.get(`/saved-models/message/getList`).then((res) => {
        if(res.data.success){
          this.Messages=res.data.data;
        }else{
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to get Saved Messages",
          //     icon: "XCircleIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to get Saved Messages`,
                icon:'XCircleIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        }
          
        });
      this.gettingSavedMessage = true;
    },
    unsaveMessage(Message) {
      this.$http.delete(`/saved-models/${Message.hashid}/unsave/message`).then((res) => {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Message removed from saved messages",
        //     icon: "XCircleIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Message removed from saved messages`,
                icon:'XCircleIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      });
      this.getSavedMessages();      
    },
  },
}
</script>

<style lang="scss" scoped>
#user-status-options ::v-deep {
  .custom-radio {
    margin-bottom: 1rem;
  }
}
</style>
