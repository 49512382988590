<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: 98%">
    <div
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div class="">
          <b-img :src="require('@/assets/images/icons/chat/newempty-chat.svg')" class="light-theme" width="300"></b-img>
          <b-img :src="require('@/assets/images/icons/chat/newempty-chatdark.svg')" class="dark-theme" width="300"></b-img>
        </div>
        <h6
          class="sidebar-toggle mt-2"
          @click="startConversation"
        >
        All Good Conversations Start With A Hello!
        </h6>
        <h4
          class="sidebar-toggle start-chat-text d-md-none d-sm-flex mt-1"
          @click="startConversation"
        >
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"
                @click.native="shallShowActiveChatContactSidebar=true"
                :to="{
                  name: 'employee-profile',
                  params: { id: activeChat.contact.hashid },
                }" target='_blank'
              />
              <h6 class="mb-0">
                {{ activeChat.contact.name }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center" v-if="activeChat.chat.hashid">
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50" v-b-toggle.search-result
              />
              <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item v-if="!activeChat.chat.isMuted" @click="confirmMuteChat(activeChat.chat)">
                    <feather-icon icon="BellOffIcon" size="18" class="mr-50 text-default"  />
                    <span>Mute</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="activeChat.chat.isMuted" @click="unMuteChat(activeChat.chat)">
                    <feather-icon icon="BellIcon" size="18" class="mr-50 text-default"  />
                    <span>Un-Mute</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="activeChat.chat.hashid" @click="confirmClearMessage(activeChat.chat)">
                    <feather-icon icon="Trash2Icon" size="18" class="mr-50 text-default" />
                    <span>Clear Messages</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!activeChat.contact.isBlocked && !activeChat.contact.isBlockedBy" @click="confirmBlockUser(activeChat.contact)">
                    <feather-icon icon="SlashIcon" size="18" class="mr-50 text-default" />
                    <span>Block {{activeChat.contact.name}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="activeChat.contact.isBlocked" @click="confirmUnblockUser(activeChat.contact)">
                    <feather-icon icon="SlashIcon" size="18" class="mr-50 text-default" />
                    <span>Un Block {{activeChat.contact.name}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item  id="show-btn"  v-b-modal.report-contact >
                    <feather-icon icon="InfoIcon" size="18" class="mr-50 text-default"/>
                    <span>Report {{activeChat.contact.name}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>
        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="userData.avatar"
            @edit-message="triggerEditMessage"
            @reply-to="triggerReplyTo"
          />
        </vue-perfect-scrollbar>
        <div class="chat-form" >
          <!-- Reply Chat Meassage -->
          <div class="reply-chat position-relative" v-if="replyTo">
            <div class="header mb-25">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_6506_22084)">
                  <path d="M18 4C18.7956 4 19.5587 4.31607 20.1213 4.87868C20.6839 5.44129 21 6.20435 21 7V15C21 15.7956 20.6839 16.5587 20.1213 17.1213C19.5587 17.6839 18.7956 18 18 18H13L8 21V18H6C5.20435 18 4.44129 17.6839 3.87868 17.1213C3.31607 16.5587 3 15.7956 3 15V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H18Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11 8L8 11L11 14" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 11H8" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_6506_22084">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <span class="ml-25">Reply to</span>
            </div>
            <div class="message-container">
              <p class="m-0" v-html="replyTo.message"></p>
            </div>
            <feather-icon
              class="ml-1 cursor-pointer reply-close"
              icon="XIcon"
              size="20"
              stroke="#636363"
              @click="closeReply"
            />
          </div>
          <!-- End Reply Chat Message -->

          <!-- Block Chat User -->
            <div class="block-user d-flex align-items-center justify-content-center" v-if="activeChat.contact.isBlocked">
              <p class="m-0">Can't send a message to blocked user <b>{{ activeChat.contact.name }}</b></p>
              <b-button type="submit" pill @click="confirmUnblockUser(activeChat.contact)">Unblock</b-button>
            </div>
            <div class="block-user d-flex align-items-center justify-content-center" v-if="activeChat.contact.isBlockedBy">
              <p class="m-0">You are Blocked, Can't send a message to this user <b>{{ activeChat.contact.name }}</b></p>
            </div>
          <!--End Block Chat User -->
          <!-- Message Input -->
          <div class="w-100 cmt-posts d-flex align-items-end justify-content-center " v-if="!activeChat.contact.isBlocked && !activeChat.contact.isBlockedBy">
            <div
              :class="(chatInputMessage.length) ? 'w-100 d-flex commentBox main-post position-relative text-active' : 'w-100 d-flex commentBox main-post position-relative' ">
              <ChatInputBox placeholder="Enter your message" className="comment-box" id="chat-inbox"
                :toolbar="false" :mentionList="employeeListOptions" width="100%" :clearInput="clearInput" :value.sync="chatInputMessage"/>  
              <b-button variant="secondary" @click="sendMessage(userData)"
                class="mb-0 search-post rounded-circle p-75">
                <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="26"></b-img>
              </b-button>
            </div>
          </div>
        </div>
        <!-- <b-form 
          class="chat-app-form"
          @submit.prevent="sendMessage(userData)"
        >
          <b-input-group class="input-group-merge form-send-message"> -->
            <!-- <b-form-textarea
              v-model="chatInputMessage"
              placeholder="Enter your message"
            /> -->
            <!-- <QuillInput :toolbar="false" className="comment-box"
                          :value.sync="chatInputMessage" placeholder="Start Typing To Add Body..."
                          :mentionList="employeeListOptions" /> -->
            <!-- <b-row class="msg-attach">
            <div class="left-option">
            <b-img :src="require('@/assets/images/icons/smile-icon.svg')" width="20"></b-img>
            <b-img :src="require('@/assets/images/icons/media-icon.svg')" width="20"></b-img> -->
            <!-- <b-img :src="require('@/assets/images/icons/mic-icon.svg')" width="16"></b-img>
            <div class="shadow-voice-message position-absolute w-100 flex h-100 items-center justify-content-center rounded-lg">
              <div class="flex items-center rounded-full px-1 py-50 inner-audio">
                <button type="submit" class="d-flex items-center justify-center pill bg-white dark:bg-gray">
                  <feather-icon class="cursor-pointer" icon="XIcon" size="18" stroke="#6E6B7B" />
                </button>
                <div class="ml-1 flex record-content items-center">
                  <div class="record-text">Recording...</div>
                  <div class="flex">
                    <div class="mr-1 record-time">
                      <div>00:00</div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="bg-primary d-flex items-center justify-center pill">
                  <feather-icon class="cursor-pointer" icon="CheckIcon" size="18" stroke="#fff" />
                </button>
              </div>
            </div> -->
            <!-- </div>
            <div class="sent-msg">
              <b-button
                variant="primary"
                type="submit"
                pill
              >
              <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="18"></b-img>
              </b-button>
            </div>
            </b-row>
          </b-input-group>
        </b-form> -->
      </div>
    </section>
    <!-- Search Result View -->
       <!-- Top Fixed Search -->
    <b-sidebar id="search-result" right shadow class="search-sideresult" backdrop no-header>
       <template #default="{ hide }">
         <!-- Header -->
        <div class="d-flex align-items-center justify-content-center content-sidebar-header p-1 border-bottom sticky-top">
          <feather-icon icon="ArrowLeftIcon" stroke="#6E6B7B" size="20" @click="hide" class="mr-25"/>
          <b-input-group class="input-group-merge round">
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search Message"
                />
          </b-input-group>
          <!-- <div class="search-values">
            <div class="up-down">
              1/23
            </div>
            <span class="separator"></span>
          </div> -->
        </div>
        <b-list-group class="search-result p-0 m-0" v-show="searchResultMessages && searchResultMessages.length">
          <b-list-group-item class="flex-column align-items-start bg-default"
            v-for="message in searchResultMessages"
          >
          <div class="d-flex">
            <b-avatar :src="message.senderAvatar" size="24" class="badge-minimal mr-75 mt-25 text-default" variant="secondary" />
            <div class="chat-info flex-grow-1">
              <h5 class="mb-0">
                {{message.senderName}} <small class="chat-time ml-25">{{ formatDateToMonthShort(message.created_at, { hour: 'numeric', minute: 'numeric' }) }}</small>
              </h5>
              <p v-html="getShadedResult(message.message)" class="mb-0" style="letter-spacing: 1px; font-size: 14px"></p>
              <!-- <div class="media-upload">
                <ul class="p-0 mx-0 my-25">
                  <li class="mr-25 mb-25">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-04.jpg')" width="40px" hight="40px"
                      rounded></b-img>
                  </li>
                  <li class="mr-25 mb-25">
                    <b-img :src="require('@/assets/images/profile/user-uploads/user-04.jpg')" width="40px" hight="40px"
                      rounded></b-img>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          </b-list-group-item>
        </b-list-group>
      </template>
    </b-sidebar>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.hashid : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="userData"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
        backdrop
        shadow
      />
    </portal>

    <!-- Show Report Modal-->
    <b-modal id="report-contact" size="lg" title="custom-community" hide-footer
              :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" class="custom-popmodal" header-class="sticky-top">
      <template #modal-header="{ close }">
        <div class="next-expand d-flex align-items-center justify-content-between w-100">
          <h4 class="mb-0">Report : Let us know why you're reporting this conversation</h4>
          <div class="d-flex align-items-center">
          <small>We won't notify the sender.</small>
          <b-link class="fullscreen-icon" @click="fullScreen = !fullScreen"> <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 26px; height: 26px;" v-b-tooltip.hover.v-default title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')" width="24px" height="24px" />
            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 26px; height: 26px;" v-b-tooltip.hover.v-default title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  width="24px" height="24px"/>
          </b-link>
          </div>
        </div>
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="confirmCloseButton" />
      </template>
      <b-form autocomplete="off" id="report-form">
        <b-row  class="schedule-post">
          <b-col md="12">
            <b-form-group class="position-relative custom-title">
              <label for="title">Issue Title</label>
              <b-form-input id="title" name="title" placeholder="Start Typing To Add Title..." />
                <div id="`toolbar-title`" class="ql-snow ql-toolbar emoji-icon border-0 p-0">
                  <span class="ql-formats">
                    <!-- <button @click="toogleDialogEmoji($event)">
                      <b-img style="width: 24px" :src="require('@/assets/images/pages/smile-icon.svg')" alt="logo" />
                    </button> -->
                  </span>
                </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="Description">Issue Description </label>
              <div class="post-content">
                <QuillInput :toolbar="true" :value.sync="report_data.body" :mentionList="employeeListOptions" className="post-editor custom-textpost" placeholder="Start Typing To Add Body..." />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12" class="text-right">
            <b-button variant="outline-danger" pill @click="reportFormSubmitted()">Report</b-button>
            <b-button variant="danger" class="ml-1" pill  @click="reportFormSubmitted(true)">Report & Block</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <audio id="newMessage" style="display: none" :src="require('@/assets/audio/notification.mp3')"></audio>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, nextTick,
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton,BModal
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

import { formatDateToMonthShort } from '@core/utils/filter'
import QuillInput from "@/components/QuillInput.vue";
import ChatInputBox from "@/components/ChatInputBox.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BModal,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
    QuillInput,
    ChatInputBox,
    ToastificationContent
  },
  setup() {
    console.log(window.Echo.connector.pusher.connection.state);
    

    const CHAT_APP_STORE_MODULE_NAME = 'chats'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })
    return {
      formatDateToMonthShort
    }
  },
  created(){
    this.fetchChatAndContacts();
    this.interval = setInterval(this.webSocketsChannels(), 1000);
  },
  data(){
    return {
      userData:this.$cookies.get("userData"),
      searchQuery:'',
      shallShowUserProfileSidebar:false,
      shallShowActiveChatContactSidebar:false,
      mqShallShowLeftSidebar:useResponsiveAppLeftSidebarVisibility().mqShallShowLeftSidebar.value,
      activeChat:{
        chat:[],
      },
      chatsContacts:[],
      contacts:[],
      resolveAvatarBadgeVariant:useChat().resolveAvatarBadgeVariant,
      chatInputMessage:'',
      perfectScrollbarSettings:{
        maxScrollbarLength: 60,
      },
      editMessage:null,
      replyTo:null,
      employeeListOptions:[],
      chatSubmitted:false,
      clearInput:false,
      fullScreen: false,
      report_data:{
        title:'',
        body:''
      },
      isBusy:false,
      reportFormSubmitClicked:false,
      myFiles:[],
      searchResultMessages:[],
      searchingMessages:false
    }
  },
  watch: {
    searchQuery(newValue){
      if(newValue && newValue != '' && newValue.length == 3){
        this.searchMessages(this.activeChat.chat.hashid,newValue);
      }
    },
    chatsContacts(){
      this.interval = setInterval(this.webSocketsChannels(), 1000);
    },
    chatInputMessage(newValue){
      if(newValue == ''){
      this.replyTo = null;
      this.editMessage = null;
      }
    }
  },
  methods: {
    getShadedResult(message){
     var result  = message.replace(this.searchQuery,'<span class="expect-result">'+this.searchQuery+'</span>')
     return result;
    },
    searchMessages(chat_id,search_str){
      this.searchingMessages = true;
      this.$http.post(`/chats/chatthread/`+chat_id+`/search`,{search_param:search_str}).then((res) => {
        if(res.data.success){
          this.searchResultMessages=res.data.data;
        }else{
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to get search in Messages",
          //     icon: "XCircleIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to get search in Messages`,
                icon:'XCircleIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
	           })
        }
          
        });
      this.searchingMessages = false;
    },
    showUserProfileSidebar(){
      this.shallShowUserProfileSidebar = true;
    },
    closeReply(){
      this.replyTo = null;
      this.chatInputMessage = '';
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
          this.$cookies.set("employeeListOptions", this.employeeListOptions);
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    fetchChatAndContacts(){
      store.dispatch('chats/fetchChatsAndContacts')
      .then(response => {
        this.chatsContacts = response.data.chatsContacts
        this.contacts = response.data.contacts
      })
    },
    scrollToBottomInChatLog(){
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    startConversation(){
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        this.mqShallShowLeftSidebar = true
      }
    },
    confirmBlockUser(data) {
      this.$swal({
        title: "Block "+data.name,
        text: `Blocked user will no longer be able to send messages to you`,
        showCancelButton: true,
        confirmButtonText: "Block",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.blockUser(data.hashid);
        }
      });
    },
    confirmClearMessage(chat){
      this.$swal({
        title: "Clear Chat Messages",
        text: `Are you Sure ? This Action cannot be undone`,
        showCancelButton: true,
        confirmButtonText: "Clear",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.clearMessage(chat.hashid);
        }
      });
    },
    confirmUnblockUser(data) {
      this.$swal({
        title: "Unblock "+data.name,
        text: `unblocked user can send messages to you again.`,
        showCancelButton: true,
        confirmButtonText: "Unblock",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.unblockUser(data.hashid);
        }
      });
    },
    confirmMuteChat(chat){
      this.$swal({
        title: "Mute Chat Notifiction",
        text: `Are you Sure ? You will not be getting browser notification`,
        showCancelButton: true,
        confirmButtonText: "Mute",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.muteChat(chat.hashid);
        }
      });
    },
    clearMessage(id){
      this.$http.get(`/chats/clear-messages/${id}`).then((res) => {
        if(res.data.success){
          this.activeChat.chat = [];
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Chat Cleared",
          //     icon: "XCircleIcon",
          //     variant: "success",
          //   },
          // });
          this.$toast({
        component: ToastificationContent,
        props: {
          title: `Chat Cleared`,
          icon:'XCircleIcon',
          variant : 'success',
          hideClose: true,

        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,

      }) 
        }
      });
    },
    confirmCloseButton() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to exit this form , if you proceed the form data will be lost.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$bvModal.hide("report-contact");
          this.report_data = {
            title: '',
            body: "",
          };
        }
      });
    },
    muteChat(id){
      this.$http.get(`/chats/mute-chat/${id}`).then((res) => {
        if(res.data.success){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Chat Muted",
          //     icon: "XCircleIcon",
          //     variant: "success",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Chat Muted`,
              icon:'XCircleIcon',
              variant : 'success',
              hideClose: true,

            },
          },
          {
            timeout : 2000,
            position : 'bottom-center',
            toastClassName:"successful-info",
            hideProgressBar : true,

          }) 
          
        }
      });
    },
    unMuteChat(chat){
      this.$http.get(`/chats/unmute-chat/${chat.hashid}`).then((res) => {
        if(res.data.success){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Chat Un-Muted",
          //     icon: "XCircleIcon",
          //     variant: "success",
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Chat Un-Muted`,
              icon:'XCircleIcon',
              variant : 'success',
              hideClose: true,

            },
          },
          {
            timeout : 2000,
            position : 'bottom-center',
            toastClassName:"successful-info",
            hideProgressBar : true,

          }) 
        }
      });
    },
    blockUser(id){
      this.$http.get(`/block-user/${id}`).then((res) => {
        if(res.data.success){
          this.activeChat.contact.isBlocked = true;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "User Blocked",
          //     icon: "XCircleIcon",
          //     variant: "success",
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `User Blocked`,
              icon:'XCircleIcon',
              variant : 'success',
              hideClose: true,

            },
          },
          {
            timeout : 2000,
            position : 'bottom-center',
            toastClassName:"successful-info",
            hideProgressBar : true,

          }) 
        }
      });
    },
    unblockUser(id){
      this.$http.get(`/unblock-user/${id}`).then((res) => {
        if(res.data.success){
          this.activeChat.contact.isBlocked = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "User unblocked",
          //     icon: "XCircleIcon",
          //     variant: "success",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `User unblocked`,
              icon:'XCircleIcon',
              variant : 'success',
              hideClose: true,

            },
          },
          {
            timeout : 2000,
            position : 'bottom-center',
            toastClassName:"successful-info",
            hideProgressBar : true,

          }) 
        }
      });
    },
    openChatOfContact(Id,type){
      var payload = { id : Id ,type:type
      };
      // Reset send message input value
      this.chatInputMessage = '';
      store.dispatch('chats/getChat', payload)
      .then(response => {
          this.activeChat = response.data

          // Set unseenMsgs to 0
          const contact = this.chatsContacts.find(c => c.hashid === Id)
          if (contact) contact.chat.unseenMsgs = 0

          // Scroll to bottom
          nextTick(() => { this.scrollToBottomInChatLog() })
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      this.mqShallShowLeftSidebar = false
    },
    updateChatThread(data){
      if(data.chat.hashid == this.activeChat.chat.hashid){
        this.activeChat.chat = data.chat;
        // Scroll to bottom
        nextTick(() => { this.scrollToBottomInChatLog() })
      }
      
      // Set Last Message in the sidebar contact
      var contact = this.chatsContacts.find(c => c.hashid === data.chat.hashid)
      if(contact){
        contact.chat.lastMessage = data.chat.lastMessage;
      }
      if(data.message.senderId != this.userData.hashid){
        // Get the audio element
        let audio = document.getElementById('newMessage');
        // Play the audio file
        audio.play();
        this.showNotification(data.message);
      }
    },
    showNotification(message) {
      if ("Notification" in window) {
          Notification.requestPermission().then(function(permission) {
              if (permission === "granted") {
                  let notification = new Notification(message.senderName, {
                    body: message.message.replace(/<\/?[^>]+(>|$)/g, ""),
                    icon: message.senderAvatar
                  });

                  // notification.onclick = function(event) {
                  //     // Handle notification click
                  // };
              }
          });
      } else {
          console.log("Browser does not support notifications");
      }
    },
    triggerEditMessage(message){
      this.editMessage = message;
      this.chatInputMessage = message.message;
    },
    triggerReplyTo(message){
      this.editMessage = null;
      this.replyTo = message;
      this.chatInputMessage = '';
    },
    sendMessage(){
      this.chatInputMessage = document.getElementById(`chat-inbox`).value;
      if (!this.chatInputMessage) return
      this.chatSubmitted = true;
      var payload = {
        contactId: this.activeChat.contact.hashid,
        // eslint-disable-next-line no-use-before-define
        senderId: this.userData.hashid,
        message: this.chatInputMessage,
        chatThreadId : this.activeChat.chat.hashid,
        messageId : null
      }
      if(this.editMessage){
        payload.messageId = this.editMessage.hashid;
      }
      if(this.replyTo){
        payload.replyTo = this.replyTo.hashid;
      }

      store.dispatch('chats/sendMessage', payload)
      .then(response => {
        const newMessageData = response.data.newMessageData;
        const chat = response.data.chat;
        // ? If it's not undefined => New chat is created (Contact is not in list of chats)
        if (chat !== undefined && !this.activeChat.chat.hashid) {
          this.activeChat = { chat, contact: this.activeChat.contact }
          this.chatsContacts.push({
            ...this.activeChat.contact,
            chat: {
              id: chat.hashid,
              lastMessage: newMessageData,
              unseenMsgs: 0,
            },
          })
        } 
        else {
          if(window.Echo.connector.pusher.connection.state != 'connected'){
              // Add message to log
              if(this.activeChat.chat){
              if(!this.activeChat.chat.messages){
                this.activeChat.chat.messages = [];
              }
              if(payload.messageId && this.activeChat.chat.messages){
                for (let index = 0; index < this.activeChat.chat.messages.length; index++) {
                  const element = this.activeChat.chat.messages[index];
                  if(element.hashid == newMessageData.messageId){
                    this.activeChat.chat.messages[index] = newMessageData;
                    // Remove the element at the specified index
                    this.activeChat.chat.messages.splice(index, 1);

                    // Insert the new element at the same index
                    this.activeChat.chat.messages.splice(index, 0, newMessageData);
                  }
                }
              }else{
                this.activeChat.chat.messages.push(newMessageData)
              }
            }else{
              this.activeChat.chat = { message : [newMessageData]};
            }
            // Set Last Message for active contact
            var contact = this.chatsContacts.find(c => c.hashid === this.activeChat.chat.hashid)
            if(contact){
              contact.chat.lastMessage = newMessageData;
            }
          }
          
          
        }

        // Reset send message input value
        this.chatInputMessage = '';
        this.editMessage = null;
        this.replyTo = null;
        this.clearInput = true;
        

        // Scroll to bottom
        nextTick(() => { this.scrollToBottomInChatLog() })
      })
      .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Failed to send message",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Failed to send message',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
        
        this.chatSubmitted = false;
    },
    reportFormSubmitted(block = false) {
      this.reportFormSubmitClicked = true;
      const formData = new FormData(document.getElementById("report-form"));

      // formData.delete("files[]"); // data is the name of the original input file; import to delete
      
      // for (let i = 0; i < this.myFiles.length; i += 1) {
      //   formData.append(`files[${i}]`, this.myFiles[i].file);
      // }
      formData.append("body", this.report_data.body);

      if (block) {
        formData.append("blockUser", true);
        formData.append("user_id", this.activeChat.contact.hashid);
      } 
      let data = {};
      data.url = "/report-models/"+this.activeChat.chat.hashid+"/save/chatThread";
      data.params = formData;
      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this post to NHR
          this.isBusy = false;
          this.$bvModal.hide("report-contact");
          this.report_data = {
            title: '',
            body: "",
          };
          this.myFiles = [];
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Report Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Report Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Report Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Report Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
      
      this.isBusy = false;
      this.reportFormSubmitClicked = false;
      this.interval = setInterval(this.webSocketsChannels(), 1000);
    },
    webSocketsChannels(){
      if(this.chatsContacts.length){
        this.chatsContacts.forEach(chat => {
          console.log(window.location.hostname+'.ChatThread.'+chat.hashid)
          window.Echo.private(window.location.hostname+'.ChatThread.'+chat.hashid)
            .listen('ChatMessageUpdated', (data) => {
              this.updateChatThread(data);
          });
        });
      }
      
    }
  },
  mounted() {
    document.body.classList.add('chat-page');
    this.fetchEmployeeList();
  },
}
</script>

<style>
.swal2-popup {
  padding: 30px 10px;
  /* .swal2-title {
    color: #FF471F;
  } */
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
@import "@core/scss/vue/pages/page-profile.scss";
</style>
